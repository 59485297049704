/** Font-Awesome
Zustzinstallation
 */
$fa-font-path: "../node_modules/font-awesome/fonts";
$fa-css-prefix: fas;
@import '../node_modules/font-awesome/scss/font-awesome.scss';

/** Zusätzliche Font-Awesome-Klasse für den Spin-Button der Loading-Animation */
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-2x {
  font-size: 2em;
}

/** Farben */
$primaryColor: #264b94;
$primaryDarkColor: #376cd5;
$primaryLightColor:#42A5F5;

/* Überschriebene Style  */
/* Überschriebene Style  */
.body-image {
  background: url("./assets/images/back_transparent_bold.png") no-repeat center center fixed , #F5F5F5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* Mehrere cards nebeneinander mit gleicher Höhe */
.flex-card-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}
.flex-card {
  padding: 8px;
}
.card-nopad {
    padding: 0 !important;
}
.loading-spinner-wrapper {
  display: flex;
  width: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}
/* horizontal zentriertes div */
.div-h-center {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
}
/* Angepasste Info-Message */
.ui-message {
  padding: .5em .5em;
  &.ui-messages-info {
    background-color: #2196F3;
    border-color: #2196F3;
    color: #ffffff;
  }
  .ui-message-icon {
    color: #ffffff !important;
    font-size: 1.75em;
    display: inline-block;
    float: left;
  }
  .ui-message-text {
    display: table-cell;
    padding-left: 1rem;
  }
}
.notification-item {
  .item-text {
    display: inline-block;
    width: 236px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/** Labels auf Formularen (außer Input) */
.form-label {
  color: $primaryColor;
}

/* DataTable und Table */
.ui-expanded-row-content {
  background-color: white;
  padding-left: 38px !important;

  h4 {
    color: #e22222;
    margin: 0.75rem 1.1rem;
  }
}
/* Angepasstes Panel für die Expanded Rows */
.ui-panel-exprow .ui-panel-titlebar  {
  font-size: 0.9em !important;
  background-color: #5e79af  !important;
}
.ui-panel-exprow .ui-panel-titlebar-icon  {
  text-align: center;
  padding-top: 0.2em;
}
.ui-panel-exprow .ui-panel-content  {
  font-size: 0.9em !important;
}

.ui-header-50 {
  .ui-accordion .ui-accordion-header {
    background-color:  #92a5c9 !important;
    color: #ffffff !important;
    > a {
      color: #ffffff !important;
    }
  }
  .ui-panel .ui-panel-titlebar {
    background-color:  #92a5c9 !important;
  }
}
.ui-header-75 {
  .ui-panel .ui-panel-titlebar {
    background-color: #5e79af  !important;
  }
}
.ui-header-100 {
  .ui-accordion .ui-accordion-header {
    background-color: $primaryColor !important;
    color: #ffffff !important;
    > a {
      color: #ffffff !important;
    }
  }
}
.ui-header-100 .ui-accordion .ui-accordion-content {
  background-color:  #ffffff !important;
}
.tt-filter-icon {
  color: gray;
  font-size: 1.2rem;
}
.tt-dropdown-filter.ui-dropdown {
  min-width: 2em;
  width: calc(100% - 1.25rem) !important;
  text-align: left !important;
}
.tt-footer-info {
  font-weight: 500;
  font-size: 1.1rem;
}
.tt-footer-note {
  font-size: 0.8rem;
}
.text-dsvs {
  color: $primaryColor !important;
}
.text-green {
  color: green;
}
.text-bold {
  font-weight: bold;
}
.text-red {
  color: #e22222 !important;
}
.text-small {
  font-size: 0.75rem;
}
.text-large {
  font-size: 1.1rem;
  line-height: 1.75;
}
/* horizontal zentriertes div */
.div-h-center {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
}
/* Datatable zusatzklassen */
.table-info-text {
  font-size: 0.8rem;
}
.table-row-expand {
  padding: 0.5em 0.5rem;
}
.col-icon {
  width: 36px;
}
.col-download {
  width: 150px;
}
/* expanded row in DataTable */
.col-expand-info {
  width: 154px;
  float: left;
}
.col-expand-info i {
  font-size: 24px;
  color: $primaryColor;
  vertical-align: middle;
  margin-right: 0.1rem;
}
.col-expand-info span {
  font-weight: 600;
  font-size: 1.1rem;
  color: #565656;
  vertical-align: middle;
}
.col-expand-details {
  float: left;
  width: calc(100% - 176px);
  padding-bottom: 0.5rem;
}
.col-expand-details .details-table {
  table-layout: auto !important;
}
.col-expand-details .details-table td {
  border: 1px solid #cacaca;
}
.col-expand-details .details-table td .image-box-tag {
  border: 1px solid #cacaca;
  width: 40px;
  text-align: left;
  color: #ffffff;
  background-color: #e22222;
  padding: 0 8px;
  margin-right: 3px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

/* Einfache Tabelle (nicht Primeng) */
.simple-table {
  table-layout: auto;
  width: 100%;
  text-align: left;
  font-weight: normal;
  border-spacing: 0;
}
.simple-table thead {
  color: white;
  background-color: $primaryColor;
}
.simple-table th, td {
  padding: 7px;
  border: 1px solid #cacaca;
  border-collapse: collapse;
}
.simple-table .align-right{
  text-align: right;
}

/** Tabelle ohne Border zur Darstellung
    Label:   Text
    Label 2: Text
 */
.table-no-border {
  table-layout: auto;
  text-align: left;
  font-weight: normal;
  border-spacing: 0;
  border: none;
  th, td {
    padding: 4px;
    border: none;
    color: #000000;
    &.label {
      padding-right: 7px;
      color: $primaryColor;
      font-weight: 700;
    }
  }
}

/** Tabelle mit Border zur Darstellung
    Label:   Text
    Label 2: Text
 */
.table-border {
  table-layout: auto;
  width: 100%;
  text-align: left;
  font-weight: normal;
  border-spacing: 0;
  border-collapse: collapse;
  th, td {
    padding: 6px;
    color: #000000;
    &.label {
      color: $primaryColor;
      font-weight: 600;
    }
  }
}

/* Eigener Scroll-Panel */
.scrpanel .ui-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
  background-color: white;
}
.scrpanel .ui-scrollpanel-bar {
  background-color: $primaryDarkColor;
  opacity: 1;
  transition: background-color .3s;
}
.scrpanel .ui-scrollpanel-bar:hover {
  background-color: $primaryLightColor;
}
.scrpanel .ui-scrollpanel-bar-x {
  visibility: hidden;
}

/* Eigener Scroll-Panel */
.scrpanel .ui-scrollpanel-wrapper {
  border-right: 9px solid #f4f4f4;
  background-color: white;
}
.scrpanel .ui-scrollpanel-bar {
  background-color: $primaryDarkColor;
  opacity: 1;
  transition: background-color .3s;
}
.scrpanel .ui-scrollpanel-bar:hover {
  background-color: $primaryLightColor;
}
.scrpanel .ui-scrollpanel-bar-x {
  visibility: hidden;
}

/* Quill editor */
.ui-editor-content {
  font-family: "Sparkasse Rg", "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Link eingeben:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'Speichern';
}
.ql-snow .ql-tooltip::before {
  content: "URL aufrufen:";
}
.ql-snow .ql-tooltip a.ql-action::after {
  content: 'Bearbeiten';
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Entfernen';
}

/* Hervorgehobener oder normaler Confirmation-Text */
.confirm-warning .ui-dialog-content {
  color: red;
}
.confirm-default .ui-dialog-content {
  color: black;
}

/* Magins */
.mt-1 {
  margin-top: 0.2rem !important;
}
.mt-2 {
  margin-top: 0.4rem !important;
}
.mt-3 {
  margin-top: 0.6rem !important;
}
.mt-4 {
  margin-top: 0.8rem !important;
}
.mt-5 {
  margin-top: 1.0rem !important;
}
.mb-1 {
  margin-bottom: 0.2rem !important;
}
.mb-2 {
  margin-bottom: 0.4rem !important;
}
.mb-3 {
  margin-bottom: 0.6rem !important;
}
.mb-4 {
  margin-bottom: 0.8rem !important;
}
.mb-5 {
  margin-bottom: 1.0rem !important;
}
.ui-table-reorder-indicator-down, .ui-table-reorder-indicator-up {
  display: none !important;
}

/* Notifications */
.ui-toast-detail {
    word-break: break-word;
}

.inline-file-list {
  .ui-fileupload-row {
    display: inline;
  }
}
